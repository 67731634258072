import { type FunctionComponent, useEffect } from 'react';
import { useAccount, useAccountStore, useUser } from 'bb/account/hooks';
import { observer } from 'bb/app/stores';

export const FetchAccountSettings: FunctionComponent = observer(() => {
    const accountStore = useAccountStore();

    const { account, error } = useAccount({
        fixed: true
    });

    useUser();

    // TODO: Remove and use useAccount instead of accountStore everywhere
    useEffect(() => {
        if (account) accountStore.setAccountSettings(account);
        else if (error) accountStore.setAccountSettingsError(error);
    }, [error, account, accountStore]);

    return null;
});
